<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import { required, minLength } from "vuelidate/lib/validators";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Create Shift Names",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },

  methods: {
    submitShiftName(){
        this.$v.$touch();
        if (this.$v.$invalid || this.processing) {
            return;
        }
        this.processing = true;
        this.$axios.post('/shift/name/store' , {
            name: this.shift_name
        })
        .then(response => {
            let data = response.data;
            this.triggerSwal(data.message, "success");
            this.$router.push("/shift-names");
            this.processing = false;
        }).catch(error => {
            this.handleErrorResponse(error.response, "error");
        }).finally(() => {
            this.processing = false;
        });  
    },
    AddformData() {
      this.shift_name.push({
          name: null,
      });
    },
    deleteRow(index) {
      // this.shift_name.splice(index, 1);
      this.shift_name.pop(); //removes the last item of the array
    },
  },
  data() {
    return {
      processing: false,
      title: "Create Shift Names",
      shift_name: [{name: null}],
    };
  },
  validations: {
    shift_name:{
        $each:{
            name: {
                required,
            },
        }
    }
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="card p-4">
        <b-form @submit.prevent="">
          <div class="row">
            <div class="col-md-6">
              <div class="col-md-12 mb-3">
                <div class="d-flex align-items-center gap-2">
                  <div class="form-field__wrapper w-100">
                    <div class="col-md-12 mt-3" v-for="(role,index) in shift_name" :key="index">
                      <div class="d-flex align-items-center gap-2">
                          <div class="icon-wrapper mt-3 pt-1">
                              <i class="ui uil-clock-ten f-18 text-muted"></i>
                          </div>
                          <div class="form-field__wrapper w-100">
                              <b-form-group id="example-date" label="Shift Name" label-for="role">
                                  <b-form-input id="role" v-model="role.name" placeholder="Enter Name Like Breakfast, Lunch, Dinner" type="text"
                                  :class="{'is-invalid':$v.shift_name.$each[index].name.$error}" ></b-form-input>
                                  <div v-if="$v.shift_name.$each[index].name.$error" class="invalid-feedback" >
                                      <span v-if="!$v.shift_name.$each[index].name.required">Name is required.</span>
                                  </div>
                              </b-form-group>
                          </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-3">
                      <div class="d-flex justify-content-between">
                          <div class="delete-fields ms-4" v-if="shift_name.length>1">
                              <i class="fas fa-trash f-12"> </i>
                              <input type="button" class="mt-3 mt-lg-0 border-0 bg-white" value="Delete" @click="deleteRow(shift_name.length)" />
                          </div>
                          <div class="add-fields">
                              <i class="fas fa-plus f-12"> </i>
                              <input type="button" class="mt-3 mt-lg-0 border-0 bg-white" value="Add Multi" @click="AddformData" />
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3 text-end">
            <SubmitButton
              :processing="processing"
              text="Create"
              @clicked="submitShiftName"
            />
          </div>
        </b-form>
      </div>
    </div>
  </Layout>
</template>
